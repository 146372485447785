import Col from "../../components/container/Col";
import Container from "../../components/container/Container";
import Row from "../../components/container/Row";
import AppleEmoji from "../../components/emoji/AppleEmoji";
import Hability from "../../components/habilities/Habilities";
import ProgressBar from "../../components/progressbar/ProgressBar";
import Section from "../../components/section/Section";
import SectionHeader from "../../components/sectionheader/SectionHeader";

export default function HabilitiesContainer() {
  return (
    <Section id={"abilities"}>
      <SectionHeader
        title={"Abilities"}
        description={"Some of my capabilities when working"}
      />
      <Container>
        <Row>
          <Col span={6}>
            <h1 style={{ fontSize: "30px", marginTop: 0 }} className="title">
              Proficiency
            </h1>
            <p className="description">
              And growing! <AppleEmoji name={"rocket"} width={15} />
            </p>
            <ProgressBar completed={70} text={"Backend programming"} />
            <ProgressBar completed={50} text={"Frontend programming"} />
            <ProgressBar completed={50} text={"System administration"} />
          </Col>
          <Col span={6} noCenter>
            <Hability
              text={
                "Extensive knowledge and experience in multiple programming languages."
              }
            />
            <Hability
              text={
                "Experience in the design, development and maintenance of computer applications and systems."
              }
            />
            <Hability
              text={
                "Knowledge in configuration, diagnosis and troubleshooting of computer hardware and networks."
              }
            />
            <Hability
              text={
                "Ability to convey ideas and technical concepts in a clear and understandable way for non-technical audiences."
              }
            />
            <Hability
              text={
                "Ability to collaborate and contribute effectively in multidisciplinary teams."
              }
            />
            <Hability
              text={
                "Proactive attitude towards learning and constant updating of technological knowledge."
              }
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
